<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col class="col-md-6 offset-md-3">
          <div class="mb-2 d-flex justify-space-between">
            <BackLink :link="{ name: 'index' }" />
            <h2 class="text-h5 grey--text text--darken-2">
              {{ title }}
            </h2>
          </div>
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
            @submit.prevent=""
          >
            <v-expansion-panels v-model="panel" accordion flat>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="flex-wrap">
                  <template v-slot:default="{ open }">
                    <template v-if="!open && data.no_rm">
                      <div class="col-10 pa-0">
                        {{
                          $store.state.settings.defaultData === "inventaris"
                            ? "Data Barang"
                            : "Identitas"
                        }}
                      </div>
                      <v-avatar
                        color="grey darken-1"
                        size="16"
                        max-width="16"
                        max-height="16"
                        tile
                        class="col-2 pa-0 ml-auto"
                      >
                        <v-icon small color="white">mdi-menu-down</v-icon>
                      </v-avatar>
                      <div class="col-12 px-0 py-2">
                        <v-row
                          v-for="(item, i) in form"
                          :key="i"
                          no-gutters
                          class="align-center text-uppercase"
                        >
                          <template
                            v-if="
                              !(
                                item.type === 'upload' ||
                                item.type === 'custom' ||
                                item.type === 'popup'
                              )
                            "
                          >
                            <v-col
                              cols="4"
                              class="pt-2 pb-1"
                              style="font-size: 8pt"
                            >
                              {{ item.label }}
                            </v-col>
                            <v-col
                              cols="8"
                              class="pt-2 pb-1 text-right text-small black--text"
                            >
                              {{
                                (item.type === "date"
                                  ? formatDate(data[item.model], "D MMM YYYY")
                                  : data[item.model]) || "-"
                              }}
                            </v-col>
                            <v-col cols="12" class="pa-0">
                              <v-divider class="col-12 my-0 pa-0"></v-divider>
                            </v-col>
                          </template>
                        </v-row>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-10 pa-0">
                        {{
                          $store.state.settings.defaultData === "inventaris"
                            ? "Data Barang"
                            : "Identitas"
                        }}
                      </div>
                      <v-avatar
                        color="#00cccc"
                        size="16"
                        max-width="16"
                        max-height="16"
                        tile
                        class="col-2 pa-0 ml-auto"
                      >
                        <v-icon small color="white">mdi-menu-up</v-icon>
                      </v-avatar>
                    </template>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <template v-for="(item, i) in form">
                      <v-col
                        :key="i"
                        :cols="item.col || '12'"
                        :class="item.col < 12 ? 'pr-2' : ''"
                      >
                        <input-field
                          v-if="item.type === 'text'"
                          :key="i"
                          v-model="data[item.model]"
                          text
                          :label="item.label"
                          :ref="item.model"
                          :hint="item.hint"
                          @keydown.enter="
                            $refs[item.model][0].$children[0].blur()
                          "
                          @blur="triggerApi(item)"
                        >
                          <template v-if="item.scanner" v-slot:append>
                            <v-btn small class="pb-2" icon tile>
                              <v-avatar tile size="17">
                                <v-img
                                  max-height="17"
                                  max-width="17"
                                  src="@/assets/images/scan_icon.png"
                                  @click.stop="onScan(item.model)"
                                />
                              </v-avatar>
                            </v-btn>
                          </template>
                        </input-field>
                        <input-field
                          v-else-if="item.type === 'select'"
                          :key="i"
                          v-model="data[item.model]"
                          select
                          :itemSelect="item.items.split(',')"
                          :label="item.label"
                        />
                        <input-field
                          v-else-if="item.type === 'autocomplete'"
                          :key="i"
                          v-model="data[item.model]"
                          autocomplete
                          :multiple="item.multiple"
                          :keyData="item.model"
                          :label="item.label"
                        >
                          <template v-if="item.scanner" v-slot:append>
                            <v-btn small class="pb-2" icon tile>
                              <v-avatar tile size="17">
                                <v-img
                                  max-height="17"
                                  max-width="17"
                                  src="@/assets/images/scan_icon.png"
                                  @click.stop="onScan(item.model)"
                                />
                              </v-avatar>
                            </v-btn>
                          </template>
                        </input-field>
                        <input-field
                          v-else-if="item.type === 'custom'"
                          :key="i"
                          v-model="data[item.model]"
                          autocomplete2
                          :itemSelect="ruangan"
                          itemText="kamar"
                          @change="selectRuangan"
                          :label="item.label"
                        />
                        <input-field
                          v-else-if="item.type === 'textarea'"
                          :key="i"
                          v-model="data[item.model]"
                          textarea
                          :label="item.label"
                        />
                        <template v-else-if="item.type === 'date'">
                          <input-field
                            :key="i"
                            v-model="data[item.model]"
                            datePicker
                            :label="item.label"
                            @clear="dateClear(item.model)"
                          />
                        </template>
                        <template v-else-if="item.type === 'upload'">
                          <div
                            :key="item.label"
                            class="text-small text-uppercase primary--text"
                          >
                            {{ item.label }}
                          </div>
                          <v-image-input
                            :key="i"
                            v-model="data[item.model]"
                            :image-quality="0.9"
                            :imageWidth="parseInt(item.width) || 400"
                            :imageHeight="parseInt(item.height) || 400"
                            :imageMaxScaling="2"
                            clearable
                            image-format="png"
                            uploadIcon="mdi-plus"
                            class="upload"
                            @input="(v) => onUpload(v, data[item.model])"
                          />
                        </template>
                      </v-col>
                    </template>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                class="mb-4"
                v-if="this.$store.state.settings.gizi"
              >
                <v-expansion-panel-header>
                  Gizi
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ResepData
                    v-if="data.resep && data.resep.length > 1"
                    class="mb-3"
                    :data="data.resep"
                    @clicked="copyObat"
                    @copyMenu="copyMenu"
                  />

                  <input-field
                    class="mb-2"
                    v-model="obat"
                    @change="obat && tambahObat(obat)"
                    autocomplete
                    keyData="giziwaktu"
                    placeholder="Tambah data"
                    solo
                    flat
                    background="#efefef"
                    autofocus
                  >
                    <template v-slot:prepend-inner>
                      <v-icon size="x-large">mdi-card-search</v-icon>
                    </template>
                    <template v-slot:selection />
                  </input-field>

                  <v-data-table
                    v-if="
                      data.resep &&
                      data.resep.length &&
                      data.resep[0].obat.length
                    "
                    dense
                    :mobile-breakpoint="0"
                    :headers="table"
                    :items="data.resep[0].obat"
                    item-key="id"
                    single-expand
                    :expanded.sync="expanded"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:header.select="{}">
                      <v-checkbox
                        v-model="multiDelete"
                        color="#00cccc"
                        hide-details
                        class="pa-0 ma-0"
                        :ripple="false"
                      ></v-checkbox>
                    </template>
                    <template v-slot:item.select="{ item }">
                      <v-checkbox
                        v-model="select"
                        color="#00cccc"
                        multiple
                        hide-details
                        class="pa-0 ma-0"
                        :ripple="false"
                        :value="item"
                      ></v-checkbox>
                    </template>
                    <template v-slot:item.no="{ item, index }">
                      <div style="font-size: 2em !important">
                        {{ index + 1 }}.
                      </div>
                    </template>
                    <template v-slot:item.obat="{ item }">
                      <div @click="expanded = [item]">
                        {{ item.obat }}
                      </div>
                    </template>
                    <template v-slot:item.signa="{ item }">
                      <v-btn x-small icon>
                        <v-icon :color="item.aturan ? '#00cccc' : ''"
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>
                    </template>
                    <template v-slot:item.tipe="{ item }">
                      <input-field
                        class="obat"
                        v-model="item.tipe"
                        select
                        :itemSelect="giziType"
                        label=""
                        :clearable="false"
                        tipe
                        @change="selectTipeGizi(item)"
                      />
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <td :colspan="table.length" class="pa-0">
                        <v-card
                          elevation="0"
                          class="my-2 rounded-lg pa-3"
                          style="border: 2px dashed lightgrey"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                              <input-field
                                v-model="item.obat"
                                text
                                label="Waktu Penyajian"
                              />
                            </v-col>

                            <v-row
                              no-gutters
                              v-if="item.tipe === 'racikan_biru'"
                            >
                              <v-col cols="6" class="py-0 pr-2">
                                <input-field
                                  v-model="item.waktu1"
                                  text
                                  label="Jam pertama"
                                />
                              </v-col>
                              <v-col cols="6" class="py-0">
                                <input-field
                                  v-model="item.waktu2"
                                  text
                                  label="Jam kedua"
                                />
                              </v-col>
                            </v-row>

                            <v-col
                              v-if="
                                !(
                                  item.tipe === 'etiket_biru' ||
                                  item.tipe === 'racikan'
                                )
                              "
                              cols="12"
                              class="py-0"
                            >
                              <input-field
                                v-model="item.aturan"
                                autocomplete
                                clearable
                                keyData="menu"
                                label="Menu"
                              >
                                <template v-slot:append-outer>
                                  <v-icon @click.stop="copyMenu(item.aturan)"
                                    >mdi-content-copy</v-icon
                                  >
                                </template>
                              </input-field>
                            </v-col>
                            <template
                              v-if="
                                !(
                                  (
                                    item.tipe === 'racikan_biru' ||
                                    item.tipe === 'racikan' ||
                                    item.tipe === 'etiket_putih'
                                  )
                                  // item.obat.toLowerCase() === 'makan pagi'
                                )
                              "
                            >
                              <v-col cols="12" class="py-0 pr-2">
                                <input-field
                                  v-model="item.waktu"
                                  autocomplete
                                  clearable
                                  keyData="snack"
                                  label="Snack"
                                />
                              </v-col>
                              <!-- <template
                                v-if="
                                  !(
                                    item.obat.toLowerCase() === 'makan malam' ||
                                    item.obat.toLowerCase() === 'snack sore'
                                  )
                                "
                              >
                                <template
                                  v-if="
                                    item.obat.toLowerCase().includes('snack') &&
                                    item.obat.toLowerCase().includes('malam')
                                  "
                                >
                                  <v-col cols="12" class="py-0 pr-2">
                                    <input-field
                                      v-model="item.waktu"
                                      autocomplete
                                      clearable
                                      keyData="snack"
                                      label="Snack"
                                    />
                                  </v-col>
                                </template>
                                <template v-else>
                                  <v-col cols="6" class="py-0 pr-2">
                                    <input-field
                                      v-model="item.snack_ganjil"
                                      autocomplete
                                      clearable
                                      keyData="snack"
                                      label="Snack Ganjil"
                                    />
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <input-field
                                      v-model="item.minuman_ganjil"
                                      autocomplete
                                      clearable
                                      keyData="minuman"
                                      label="Minuman Ganjil"
                                    />
                                  </v-col>
                                  <v-col cols="6" class="py-0 pr-2">
                                    <input-field
                                      v-model="item.snack_genap"
                                      autocomplete
                                      clearable
                                      keyData="snack"
                                      label="Snack Genap"
                                    />
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <input-field
                                      v-model="item.minuman_genap"
                                      autocomplete
                                      clearable
                                      keyData="minuman"
                                      label="Minuman Genap"
                                    />
                                  </v-col>
                                </template>
                              </template>
                              <template v-else>
                                <v-col cols="6" class="py-0 pr-2">
                                  <input-field
                                    v-model="item.snack_ganjil"
                                    autocomplete
                                    clearable
                                    keyData="snack"
                                    label="Snack Biasa"
                                  />
                                </v-col>
                                <v-col cols="6" class="py-0 pr-2">
                                  <input-field
                                    v-model="item.snack_genap"
                                    autocomplete
                                    clearable
                                    keyData="snack"
                                    label="Snack Gorengan"
                                  />
                                </v-col>
                              </template> -->
                            </template>
                          </v-row>
                        </v-card>
                      </td>
                    </template>
                  </v-data-table>
                  <v-card-actions
                    class="d-flex justify-space-between px-0 pb-0 mb-n3"
                  >
                    <v-btn
                      v-show="!!select.length"
                      depressed
                      small
                      color="red"
                      class="col white--text"
                      @click="massDelete"
                    >
                      HAPUS ({{ select.length }})
                    </v-btn>
                    <v-menu class="v-btn" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="!!select.length"
                          class="col"
                          color="blue-grey"
                          dark
                          depressed
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          Ubah Tipe ({{ select.length }})
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(item, index) in giziType"
                          :key="index"
                          link
                        >
                          <v-list-item-title
                            class="text-uppercase"
                            @click="massChange(item.value)"
                          >
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                class="mb-4"
                v-if="this.$store.state.settings.resep.enable === 'enable'"
              >
                <v-expansion-panel-header>
                  Resep
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ResepData
                    v-if="data.resep && data.resep.length > 1"
                    class="mb-3"
                    :data="data.resep"
                    @clicked="copyObat"
                  />

                  <input-field
                    class="mb-2"
                    v-model="obat"
                    @change="obat && tambahObat(obat)"
                    autocomplete
                    keyData="obat"
                    placeholder="Cari Obat"
                    solo
                    flat
                    background="#efefef"
                    autofocus
                  >
                    <template v-slot:prepend-inner>
                      <v-icon size="x-large">mdi-card-search</v-icon>
                    </template>
                    <template v-slot:selection />
                  </input-field>

                  <v-data-table
                    v-if="
                      data.resep &&
                      data.resep.length &&
                      data.resep[0].obat.length
                    "
                    dense
                    :mobile-breakpoint="0"
                    :headers="table"
                    :items="data.resep[0].obat"
                    item-key="id"
                    single-expand
                    :expanded.sync="expanded"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:header.select="{}">
                      <v-checkbox
                        v-model="multiDelete"
                        color="#00cccc"
                        hide-details
                        class="pa-0 ma-0"
                        :ripple="false"
                      ></v-checkbox>
                    </template>
                    <template v-slot:item.select="{ item }">
                      <v-checkbox
                        v-model="select"
                        color="#00cccc"
                        multiple
                        hide-details
                        class="pa-0 ma-0"
                        :ripple="false"
                        :value="item"
                      ></v-checkbox>
                    </template>
                    <template v-slot:item.no="{ item, index }">
                      <div style="font-size: 2em !important">
                        {{ index + 1 }}.
                      </div>
                    </template>
                    <template v-slot:item.obat="{ item }">
                      <template v-if="item.tipe.includes('racikan')">
                        <div @click="expanded = [item]">
                          {{ item.obat }}
                          <ul class="pl-1" style="list-style-type: none">
                            <li
                              v-for="racikan in item.infus.filter(
                                (a) => a.obat
                              )"
                              :key="racikan.obat"
                              class="text-caption"
                            >
                              - {{ racikan.obat }}
                            </li>
                          </ul>
                        </div>
                      </template>
                      <template v-else>
                        <div @click="expanded = [item]">
                          {{ item.obat }}
                        </div>
                      </template>
                    </template>
                    <template v-slot:item.signa="{ item }">
                      <v-btn x-small icon>
                        <v-icon :color="item.aturan ? '#00cccc' : ''"
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>
                    </template>
                    <template v-slot:item.tipe="{ item }">
                      <input-field
                        class="obat"
                        v-model="item.tipe"
                        select
                        :itemSelect="obatType"
                        label=""
                        :clearable="false"
                        tipe
                        @change="selectTipe(item)"
                      />
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <td :colspan="table.length" class="pa-0">
                        <v-card
                          elevation="0"
                          class="my-2 rounded-lg pa-3"
                          style="border: 2px dashed lightgrey"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                              <input-field
                                v-if="
                                  item.obat &&
                                  item.obat.toLowerCase().includes('racikan')
                                "
                                v-model="item.obat"
                                text
                                label="Nama Racikan"
                                autofocus
                                key="obat"
                              />
                              <input-field
                                v-else
                                v-model="item.obat"
                                autocomplete
                                keyData="obat"
                                label="Nama Obat"
                                autofocus
                                key="obat"
                              />
                            </v-col>
                            <template
                              v-if="
                                item.tipe === 'injeksi' ||
                                item.tipe.includes('racikan') ||
                                item.obat?.toLowerCase().includes('racikan')
                              "
                            >
                              <v-col cols="12" class="py-0"
                                ><h3 class="text-overline">Isi Obat</h3></v-col
                              >
                              <template v-for="(racikan, n) in item.infus">
                                <v-row no-gutters :key="n">
                                  <v-col
                                    :cols="
                                      item.obat
                                        ?.toLowerCase()
                                        .includes('racikan')
                                        ? 10
                                        : 5
                                    "
                                    class="py-0"
                                  >
                                    <input-field
                                      @change="addRacikan(item, racikan)"
                                      @click:clear="item.infus.splice(n, 1)"
                                      v-model="racikan.obat"
                                      autocomplete
                                      keyData="obat"
                                      label="Nama Obat"
                                    />
                                  </v-col>
                                  <v-col cols="2" class="py-0 px-1">
                                    <input-field
                                      v-model="racikan.qty"
                                      text
                                      :clearable="false"
                                      label="Dosis"
                                    />
                                  </v-col>
                                  <v-col
                                    v-if="
                                      !item.obat
                                        ?.toLowerCase()
                                        .includes('racikan')
                                    "
                                    cols="5"
                                    class="py-0"
                                  >
                                    <input-field
                                      v-model="racikan.waktu"
                                      autocomplete
                                      :clearable="false"
                                      keyData="jam"
                                      label="Jam"
                                    />
                                  </v-col>
                                </v-row>
                              </template>
                              <v-col cols="12" class="my-2" />
                            </template>

                            <v-col cols="4" class="py-0">
                              <input-field
                                v-if="item.tipe === 'infus'"
                                v-model="item.qty"
                                text
                                :clearable="false"
                                label="Dosis Obat"
                              />
                              <input-field
                                v-else
                                v-model="item.qty"
                                text
                                type="number"
                                :clearable="false"
                                label="Qty"
                              />
                            </v-col>
                            <v-col cols="4" class="py-0 px-1">
                              <input-field
                                v-if="item.tipe === 'infus'"
                                v-model="item.catatan"
                                text
                                label="Volume Total"
                              />
                              <input-field
                                v-else
                                v-model="item.satuan"
                                autocomplete
                                keyData="satuan"
                                label="Sediaan"
                              />
                            </v-col>
                            <v-col cols="4" class="py-0">
                              <input-field
                                v-model="item.expired"
                                text
                                :clearable="false"
                                label="Expired"
                              />
                            </v-col>

                            <template v-if="item.tipe === 'infus'">
                              <v-row no-gutters>
                                <v-col cols="12">
                                  <input-field
                                    v-model="item.satuan"
                                    autocomplete
                                    keyData="obat"
                                    label="Cairan"
                                  />
                                </v-col>
                              </v-row>
                            </template>
                            <template v-if="item.tipe.includes('udd')">
                              <v-col cols="12" class="py-0"
                                ><h3 class="text-overline">Waktu Pemberian</h3>
                              </v-col>
                              <v-row no-gutters>
                                <template v-for="(waktu, n) in item.waktu_udd">
                                  <v-col
                                    :key="`${waktu.waktu} ${n}`"
                                    v-if="
                                      $store.state.settings.resep
                                        .waktu_fleksibel
                                    "
                                    cols="8"
                                    class="pr-2"
                                  >
                                    <input-field
                                      v-model="waktu.waktu"
                                      @change="addWaktu(item)"
                                      autocomplete
                                      keyData="waktu_udd"
                                      label="Jam"
                                    />
                                  </v-col>
                                  <v-col
                                    :key="n"
                                    :cols="
                                      $store.state.settings.resep
                                        .waktu_fleksibel
                                        ? '4'
                                        : '6'
                                    "
                                    class="pr-2"
                                  >
                                    <input-field
                                      v-model="waktu.dosis"
                                      text
                                      :label="
                                        $store.state.settings.resep
                                          .waktu_fleksibel
                                          ? 'Dosis'
                                          : waktu.waktu
                                      "
                                    />
                                    <!-- <v-checkbox
                                      v-model="waktu.dosis"
                                      dense
                                      hide-details="auto"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="text-uppercase"
                                          style="font-size: 0.7em"
                                        >
                                          {{
                                            $store.state.settings.resep
                                              .waktu_fleksibel
                                              ? "Dosis"
                                              : waktu.waktu
                                          }}
                                        </span>
                                      </template>
                                    </v-checkbox> -->
                                    <!-- <v-col cols="12" class="py-0">
                                      <input-field
                                        v-model="item.aturan"
                                        autocomplete
                                        keyData="catatan"
                                        label="Peringatan"
                                        :key="'aturan' + item.id"
                                      />
                                    </v-col> -->
                                  </v-col>
                                </template>
                              </v-row>
                              <v-col cols="12" class="my-2" />
                            </template>
                            <v-col v-else cols="12" class="py-0">
                              <v-row
                                v-if="
                                  $store.state.settings.resep.signa_format &&
                                  item.signa
                                "
                                no-gutters
                                class="mb-4"
                              >
                                <v-col cols="12" class="py-0"
                                  ><h3 class="text-overline">
                                    Format Signa
                                  </h3></v-col
                                >
                                <v-col cols="3" class="py-0">
                                  <input-field
                                    v-model="item.signa.aturan"
                                    autocomplete2
                                    :itemSelect="
                                      $store.state.settings.resep.signa
                                    "
                                    itemText="aturan"
                                    clearable
                                    label="Signa"
                                    @change="signaFormat(item)"
                                  />
                                </v-col>
                                <v-col cols="4" class="py-0 px-1">
                                  <input-field
                                    v-model="item.signa.dosis"
                                    autocomplete
                                    keyData="dosis"
                                    label="Dosis"
                                    clearable
                                    @change="signaFormat(item)"
                                  />
                                </v-col>
                                <v-col cols="5" class="py-0">
                                  <input-field
                                    v-model="item.signa.keterangan"
                                    autocomplete
                                    keyData="keterangan"
                                    label="Keterangan"
                                    clearable
                                    @change="signaFormat(item)"
                                  />
                                </v-col>
                              </v-row>
                              <input-field
                                v-model="item.aturan"
                                :autocomplete="
                                  $store.state.settings.resep.signa_format &&
                                  item.signa
                                    ? false
                                    : true
                                "
                                :textarea="
                                  $store.state.settings.resep.signa_format &&
                                  item.signa
                                    ? true
                                    : false
                                "
                                keyData="aturan"
                                signa
                                label="Aturan Penggunaan"
                                :key="'aturan' + item.id"
                              />
                              <input-field
                                v-model="item.waktu"
                                :autocomplete="
                                  $store.state.settings.resep.signa_format &&
                                  item.signa
                                    ? false
                                    : true
                                "
                                :textarea="
                                  $store.state.settings.resep.signa_format &&
                                  item.signa
                                    ? true
                                    : false
                                "
                                keyData="waktu"
                                label="Waktu Penggunaan"
                                :key="'waktu' + item.id"
                              />
                              <input-field
                                v-if="item.tipe !== 'infus'"
                                v-model="item.catatan"
                                autocomplete
                                keyData="catatan"
                                label="Peringatan"
                                :key="'catatan' + item.id"
                              />
                            </v-col>
                          </v-row>
                        </v-card>
                      </td>
                    </template>
                  </v-data-table>
                  <v-card-actions
                    class="d-flex justify-space-between px-0 pb-0 mb-n3"
                  >
                    <v-btn
                      v-show="!!select.length"
                      depressed
                      small
                      color="red"
                      class="col white--text"
                      @click="massDelete"
                    >
                      HAPUS ({{ select.length }})
                    </v-btn>
                    <v-btn
                      v-show="!!select.length"
                      depressed
                      small
                      dark
                      color="grey"
                      class="col"
                      @click="buatRacikan"
                    >
                      Racikan ({{ select.length }})
                    </v-btn>
                    <v-menu class="v-btn" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="!!select.length"
                          class="col"
                          color="blue-grey"
                          dark
                          depressed
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          Ubah Tipe ({{ select.length }})
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(item, index) in obatType"
                          :key="index"
                          link
                        >
                          <v-list-item-title
                            class="text-uppercase"
                            @click="massChange(item.value)"
                          >
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions class="d-flex justify-space-between pa-0">
              <v-btn
                v-if="
                  $store.state.settings.custom &&
                  $store.state.settings.custom.enable
                "
                depressed
                small
                outlined
                color="primary"
                class="col-1"
                @click="doPrint(null, true)"
              >
                <v-icon>{{
                  $store.state.settings.custom.icon || "mdi-credit-card-edit"
                }}</v-icon>
              </v-btn>
              <v-btn
                v-if="id"
                depressed
                small
                outlined
                class="grey--text col"
                @click="doPrint()"
              >
                CETAK ETIKET
              </v-btn>
              <v-btn
                v-else
                depressed
                small
                outlined
                class="grey--text col"
                @click="reset"
              >
                HAPUS SEMUA
              </v-btn>
              <v-btn
                depressed
                small
                :loading="isLoading"
                color="primary"
                class="col"
                @click="submit"
              >
                SIMPAN
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <a
      href="#/form"
      v-if="id"
      style="position: fixed; top: 60%; z-index: 99; right: 0"
    >
      <v-img src="@/assets/images/plus-side.png" max-width="20"></v-img>
    </a>

    <modal-print ref="modalPrint" :type="type" unit="farmasi" />
    <modal-delete ref="modalDelete" />
    <scanner ref="scanner" />
    <Popup v-if="popup && data[form.find((x) => x.type === 'popup')['model']]">
      <template v-slot:header>
        <span v-html="form.find((x) => x.type === 'popup')['label']" />
      </template>
      <template v-slot:content>
        <div v-html="data[form.find((x) => x.type === 'popup')['model']]" />
      </template>
    </Popup>
  </v-main>
</template>

<script>
import InputField from "@/components/InputField";
import ModalPrint from "@/components/ModalPrint";
import ModalDelete from "@/components/ModalDelete";
import Scanner from "@/components/Scanner";

export default {
  name: "Form",
  components: {
    InputField,
    ModalPrint,
    ModalDelete,
    Scanner,
    ResepData: () => import("@/components/ResepData"),
    VImageInput: () => import("vuetify-image-input/a-la-carte"),
    Popup: () => import("@/components/Popup"),
  },
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      valid: true,
      lazy: false,
      isLoading: false,
      apiLoading: false,
      resep: [],
      obat: null,
      panel: 0,
      data: this.getClearFormObject(),
      select: [],
      copy: [],
      giziTable: [
        {
          text: "",
          value: "no",
          class: "px-0",
          cellClass: "px-0",
          width: "5%",
          sortable: false,
        },
        {
          text: "Waktu Penyajian",
          value: "waktu",
          class: "pl-0 text-uppercase",
          cellClass: "pl-0 text-uppercase",
        },
        {
          text: "Menu",
          value: "menu",
          class: "pl-0 text-uppercase",
          cellClass: "pl-0 text-uppercase",
        },
        {
          text: "",
          value: "check",
          class: "px-0",
          cellClass: "px-0",
          width: "5%",
          sortable: false,
        },
      ],
      table: [
        {
          text: "",
          value: "select",
          class: "px-0 text-uppercase",
          cellClass: "px-0",
          width: "5%",
          sortable: false,
        },
        {
          text: "",
          value: "no",
          class: "px-0",
          cellClass: "px-0",
          width: "5%",
          sortable: false,
        },
        {
          text: "Item",
          value: "obat",
          class: "pl-0 text-uppercase",
          cellClass: "pl-0 text-uppercase",
        },
        {
          text: "Tipe",
          value: "tipe",
          class: "pl-0 text-uppercase",
          cellClass: "pl-0 text-uppercase",
          width: "21%",
        },
        {
          text: "",
          value: "signa",
          class: "px-0",
          cellClass: "px-0",
          width: "5%",
          sortable: false,
        },
      ],
      expanded: [],
      multiDelete: false,
      datePicker: false,
      ruangan: [],
      popup: false,
    };
  },
  created() {
    if (!this.id) {
      this.data.resep.push({ id: Date.now(), obat: [] });
      if (this.$store.state.settings.gizi) {
        this.data.resep[0].obat = this.giziObject();
        this.getRuangan();
      }
    }
    this.getData();
    this.panel =
      this.id &&
      (this.$store.state.settings.resep.enable === "enable" ||
        this.$store.state.settings.gizi)
        ? 1
        : 0;
  },
  computed: {
    form() {
      return this.$store.state.settings.form;
    },
    type() {
      if (
        this.$store.state.settings.resep.enable === "enable" ||
        this.$store.state.settings.gizi
      ) {
        return "resep";
      } else if (this.$store.state.settings.defaultData === "inventaris") {
        return "inventaris";
      } else if (this.$store.state.settings.defaultData === "staff") {
        return "staff";
      } else {
        if (
          this.data.kelamin &&
          this.data.kelamin.toLowerCase() === "perempuan"
        ) {
          return "gelangpink";
        } else {
          return "gelangbiru";
        }
      }
    },
    waktu_udd() {
      return this.$store.state.settings.resep.waktu_udd;
    },
    title() {
      if (this.$store.state.settings.resep.enable === "enable") {
        return "Terapi";
      } else if (this.$store.state.settings.defaultData === "inventaris") {
        return "Inventaris";
      } else if (this.$store.state.settings.defaultData === "staff") {
        return "Staff";
      }
      return "Pasien";
    },
    obatType() {
      let type = [
        { text: "Obat Oral", value: "etiket_putih" },
        { text: "Obat Luar", value: "etiket_biru" },
        { text: "Racikan", value: "racikan" },
        { text: "Racikan Luar", value: "racikan_biru" },
        { text: "UDD", value: "udd" },
        { text: "UDD Biru", value: "udd_biru" },
        { text: "Infus", value: "infus" },
        { text: "Injeksi", value: "injeksi" },
      ];

      return type.filter((b) =>
        this.$store.state.settings.resep.enable_tipe.includes(b.value)
      );
    },
    giziType() {
      let type = [
        { text: "Makan", value: "etiket_putih" },
        { text: "Diit Cair", value: "racikan_biru" },
        { text: "Snack", value: "etiket_biru" },
        { text: "Parcel", value: "racikan" },
      ];

      return type;
    },
  },
  methods: {
    getRuangan() {
      if (this.$store.state.settings.gizi) {
        this.axios
          .get(`${window.rawUrl}/assets/js/ruangan.js`)
          .then((r) => {
            this.ruangan = r.data;
            // console.log(this.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    },
    selectRuangan(v) {
      if (v) {
        this.data.ruangan = String(v.kamar);
      }
    },
    dateClear(model) {
      this.data[model] = null;
      this.datePicker = false;
    },
    addRacikan(item, racikan) {
      if (racikan.obat && item.infus) item.infus.push(this.getRacikanObject());
    },
    getRacikanObject() {
      return {
        obat: null,
        qty: null,
        waktu: null,
      };
    },
    selectTipe(item) {
      if (
        item.tipe === "infus" ||
        item.tipe === "injeksi" ||
        item.tipe.includes("racikan")
      ) {
        if (!item.infus) item.infus = new Array();

        item.infus.push(this.getRacikanObject());
      } else if (item.tipe === "udd") {
        if (this.$store.state.settings.resep.waktu_fleksibel) {
          if (!item.waktu_udd) item.waktu_udd = new Array();
          item.waktu_udd.push(this.uddObject());
        } else {
          item.waktu_udd = this.uddObject();
        }
      } else if (item.tipe === "udd_biru") {
        if (this.$store.state.settings.resep.waktu_fleksibel) {
          if (!item.waktu_udd) item.waktu_udd = new Array();
          item.waktu_udd.push(this.uddObject());
        } else {
          item.waktu_udd = this.uddObject("biru");
        }
      }
    },
    selectTipeGizi(item) {
      if (item.tipe === "racikan_biru") {
        if (item.obat.toLowerCase().includes("pagi")) {
          item.waktu1 = "07.00";
          item.waktu2 = "10.00";
          item.ke1 = "1";
          item.ke2 = "2";
        } else if (item.obat.toLowerCase().includes("siang")) {
          item.waktu1 = "13.00";
          item.waktu2 = "16.00";
          item.ke1 = "3";
          item.ke2 = "4";
        } else if (item.obat.toLowerCase().includes("malam")) {
          item.waktu1 = "19.00";
          item.waktu2 = "22.00";
          item.ke1 = "5";
          item.ke2 = "6";
        }
      }
    },
    copyObat(item) {
      if (this.copy.includes(item.id) || item.id == null) {
        return;
      }
      let obat = JSON.parse(JSON.stringify(item));
      const nanoid = require("nanoid/generate");
      this.copy.push(item.id);
      obat.id = nanoid("12345abcdefghijklmnopqrstuvwxyz", 6);
      obat.tipe = obat.tipe ? obat.tipe : "etiket_putih";
      this.data.resep[0].obat.push(obat);
    },
    copyMenu(menu) {
      console.log(menu);
      this.data.resep[0].obat.map((x) => (x.aturan = menu));
    },
    uddObject(tipe) {
      let waktu_udd;
      if (this.$store.state.settings.resep.waktu_fleksibel) {
        waktu_udd = {
          waktu: null,
          dosis: null,
        };
      } else {
        waktu_udd = JSON.parse(
          JSON.stringify(this.$store.state.settings.resep.waktu_udd)
        );
        if (tipe && tipe === "biru") {
          waktu_udd = JSON.parse(
            JSON.stringify(this.$store.state.settings.resep.waktu_udd_biru)
          );
        }
      }
      return waktu_udd;
    },
    addWaktu(item) {
      if (item.waktu_udd) item.waktu_udd.push(this.uddObject());
    },
    signaFormat(item) {
      console.log(item.signa.aturan);
      let aturan = null;
      if (item.signa.aturan) {
        aturan = item.signa.aturan.format;
        aturan = aturan.replace("{aturan}", item.signa.aturan.aturan || "");
        aturan = aturan.replace("{dosis}", item.signa.dosis || "");
        aturan = aturan.replace("{satuan}", item.satuan || "");
        aturan = aturan.replace("{keterangan}", item.signa.keterangan || "");
        item.aturan = aturan;
        item.waktu = item.signa.aturan.waktu;
      }
    },
    tambahObat(val) {
      if (val) {
        const nanoid = require("nanoid/generate");
        let tipe = "etiket_putih";
        if (val.toLowerCase().includes("snack")) {
          tipe = "etiket_biru";
        } else if (val.toLowerCase().includes("parcel")) {
          tipe = "racikan";
        }

        let defaults = this.$store.getters.settings.resep;
        let data = {
          id: nanoid("12345abcdefghijklmnopqrstuvwxyz", 6),
          obat: val,
          tipe: defaults.tipe || tipe,
          aturan: defaults.aturan,
          waktu: defaults.waktu,
          waktu_udd: this.$store.state.settings.resep.waktu_fleksibel
            ? new Array()
            : this.uddObject(),
          catatan: defaults.catatan,
          satuan: defaults.satuan,
          qty: defaults.qty,
          infus: new Array(),
          signa: { aturan: null, dosis: null, keterangan: null },
        };

        this.data.resep[0].obat.push(data);
        this.$nextTick(() => {
          this.obat = null;
        });
        this.expanded = [
          this.data.resep[0].obat[this.data.resep[0].obat.length - 1],
        ];
      }
    },
    massChange(tipe) {
      this.select.forEach((item) => {
        item.tipe = tipe;
        if (this.$store.state.settings.gizi) {
          this.selectTipeGizi(item);
        } else {
          this.selectTipe(item);
        }
      });
      this.select = [];
      this.multiDelete = false;
    },
    massDelete() {
      // console.log(val);
      this.$refs.modalDelete
        .open(this.select)
        .then((res) => {
          this.data.resep[0].obat = this.data.resep[0].obat.filter((resep) =>
            res.every((item) => item.id !== resep.id)
          );
          this.$root.snackbar.show({
            text: this.select.length + " berhasil dihapus",
          });
          // console.log(this.data.resep[0].obat);
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.multiDelete = false;
          this.select = [];
        });
    },
    buatRacikan() {
      // console.log(this.select);
      const nanoid = require("nanoid/generate");
      this.select.push(this.getRacikanObject());
      let racikan = this.select.map(function (item) {
        return { obat: item.obat, qty: item.qty, satuan: item.satuan };
      });
      let defaults = this.$store.getters.settings.resep;
      this.data.resep[0].obat.unshift({
        id: nanoid("1234567890abcdef", 6),
        obat: "Obat Racikan",
        tipe: "racikan",
        aturan: defaults.aturan,
        waktu: defaults.waktu,
        catatan: defaults.catatan,
        satuan: defaults.satuan,
        qty: defaults.qty,
        infus: racikan,
        signa: { aturan: null, dosis: null, keterangan: null },
      });
      this.data.resep[0].obat = this.data.resep[0].obat.filter((resep) =>
        this.select.every((item) => item.obat !== resep.obat)
      );
      this.select = this.select.map((resep) => {
        resep.jenis = "racikan";
        return resep;
      });
      this.select = this.select.filter((resep) => resep.obat);
      // this.data.resep[0].obat = this.data.resep[0].obat.concat(this.select);
      // console.log(this.select, this.form.resep.obat);
      // console.log(this.data.resep[0].obat);
      this.select = [];
    },
    reset() {
      this.data = this.getClearFormObject();
      this.data.resep.push({ id: Date.now(), obat: [] });

      if (this.$store.state.settings.gizi)
        this.data.resep[0].obat = this.giziObject();

      this.panel = 0;
      this.popup = false;
    },
    getClearFormObject() {
      let form = this.$store.state.settings.form.map((item) => {
        return item.model;
      });
      let data = form.reduce((acc, curr) => ((acc[curr] = null), acc), {});
      Object.assign(data, { resep: [] });
      if (this.$store.state.settings.defaultData === "inventaris") {
        Object.assign(data, { type: "inventaris" });
      }
      return data;
    },
    giziObject() {
      const nanoid = require("nanoid/generate");
      let obj = [
        {
          id: nanoid("1234567890abcdef", 6),
          obat: "Makan Pagi",
          tipe: "etiket_putih",
          aturan: null,
          waktu: null,
          catatan: null,
        },
        {
          id: nanoid("1234567890abcdef", 6),
          obat: "Makan Siang",
          tipe: "etiket_putih",
          aturan: null,
          waktu: null,
          catatan: null,
        },
        {
          id: nanoid("1234567890abcdef", 6),
          obat: "Makan Sore",
          tipe: "etiket_putih",
          aturan: null,
          waktu: null,
          catatan: null,
        },
        {
          id: nanoid("1234567890abcdef", 6),
          obat: "Snack Pagi",
          tipe: "etiket_biru",
          aturan: null,
          waktu: null,
          catatan: null,
        },
        {
          id: nanoid("1234567890abcdef", 6),
          obat: "Snack Sore",
          tipe: "etiket_biru",
          aturan: null,
          waktu: null,
          catatan: null,
        },
      ];

      if (this.$store.state.settings.giziObject) {
        obj = this.$store.state.settings.giziObject.map((item) => {
          item.id = nanoid("1234567890abcdef", 6);
          item.aturan = null;
          item.waktu = null;
          item.catatan = null;
          return item;
        });
      }
      return obj;
    },
    selectGizi(v, data) {
      if (v) {
        let pagi = data.find((x) => x.waktu === "pagi");
        data.map((item) => {
          item.menu = pagi.menu;
          item.minuman = pagi.minuman;
          item.snack = pagi.snack;
          return item;
        });
      }
    },
    async getPasien() {
      this.apiLoading = true;
      // Lazily load input items
      await this.axios
        .get(`pasien?no_rm=${this.data.no_rm}&$sort[updated_at]=-1`)
        .then((r) => {
          if (
            r.data.data.length &&
            r.data.data[0].no_rm &&
            r.data.data[0].nama
          ) {
            Object.assign(
              r.data.data[0].content,
              Object.fromEntries(
                Object.entries(r.data.data[0]).filter(
                  ([key]) => !["content"].includes(key)
                )
              )
            );
            this.data = r.data.data[0].content;
            this.data.resep = this.data.resep.filter(
              (item) => item.obat && item.obat.length
            );
            this.data.resep.unshift({ id: Date.now(), obat: [] });

            if (this.$store.state.settings.gizi)
              this.data.resep[0].obat = this.giziObject();
            // if (this.id !== this.data._id) {
            //   this.$router.push({
            //     name: "form.edit",
            //     params: { id: this.data._id },
            //   });
            // }
            if (this.$store.state.settings.resep.enable === "enable") {
              this.panel = 1;
            }
            // console.log(this.data);
          } else {
            if (this.$store.state.settings.api.enable) {
              this.apiPasien();
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
          if (this.$store.state.settings.api.enable) {
            this.apiPasien();
          }
        })
        .finally(() => (this.apiLoading = false));
    },
    triggerApi(item) {
      if (this.$store.state.settings.apiFarmasi.enable) {
        if (
          item.model === this.$store.state.settings.apiFarmasi.trigger &&
          this.data[item.model]
        ) {
          this.apiFarmasi();
        }
      } else if (this.$store.state.settings.api.enable) {
        if (item.model === this.$store.state.settings.api.trigger) {
          if (item.model === "no_rm" && this.data.no_rm) {
            this.getPasien();
          } else {
            this.apiPasien(this.data[item.model]);
          }
        }
        // this.apiPasien(this.data[item.model]);
      } else if (item.model === "no_rm" && this.data.no_rm) {
        this.getPasien();
      }
    },
    async apiPasien(model) {
      let query = model || this.data[this.$store.state.settings.api.trigger];
      if (query) {
        this.$emit("showProgress");
        let r = await window.apiPasien(query);
        this.$emit("showProgress");
        // console.log(r);
        if (r[this.$store.state.settings.api.trigger] === query) {
          // this.data = r;
          Object.entries(r).forEach(([key, value]) => {
            // commit("setting", { key: key, value: value });
            this.data[key] = value;
          });
          this.data.resep.push({ id: Date.now(), obat: [] });
          if (this.$store.state.settings.gizi)
            this.data.resep[0].obat = this.giziObject();
        } else {
          this.$root.snackbar.show({
            text: "Data Pasien tidak ditemukan.",
            toast: true,
          });
        }
      }
    },
    async apiFarmasi() {
      let query = this.$store.state.settings.apiFarmasi.data;
      let data = {};
      query.forEach((item) => {
        data[item] = this.data[item];
      });

      this.$emit("showProgress");
      let r = await window.apiFarmasi(data);
      this.$emit("showProgress");
      // console.log(r);
      if (r && r.data) {
        this.data = r.data;
        this.data.resep[0] = r.resep;
        this.data.resep[0].obat = this.data.resep[0].obat.map((item) => {
          const nanoid = require("nanoid/generate");
          item.id = nanoid("12345abcdefghijklmnopqrstuvwxyz", 6);
          return item;
        });
        this.panel = 1;
        if (this.form.find((x) => x.type === "popup")) {
          console.log("popup");
          this.popup = true;
        }
      } else {
        if (
          this.$store.state.settings.apiFarmasi.trigger === "no_rm" &&
          data.no_rm
        ) {
          this.getPasien();
        } else {
          this.$root.snackbar.show({
            text: "Data tidak ditemukan!",
            toast: true,
            color: "error",
          });
        }
      }
    },
    getData() {
      if (this.id) {
        this.$emit("showProgress");
        this.axios
          .get(`/pasien/${this.id}`)
          .then((r) => {
            Object.assign(
              r.data.content,
              Object.fromEntries(
                Object.entries(r.data).filter(
                  ([key]) => !["content"].includes(key)
                )
              )
            );
            this.data = r.data.content;
            if (this.form.find((x) => x.type === "popup")) {
              this.popup = true;
            }
            // console.log(this.data);
          })
          .catch((e) => {
            console.log(e.message);
          })
          .finally(() => {
            this.$emit("showProgress");
          });
      }
    },
    submit() {
      this.isLoading = true;
      let method = "post";
      let url = "/pasien";
      let status = "antrian";

      if (this.id) {
        status = this.data.status;
      }
      if (this.id || this.data._id) {
        method = "put";
        url = `/pasien/${this.data._id}`;
      }
      this.data.resep = this.data.resep.slice(0, 6);
      // .filter((item) => item.obat && item.obat.length);
      console.log(status);
      this.axios({
        method,
        url: url,
        data: {
          no_rm: this.data.no_rm,
          nama: this.data.nama,
          content: Object.fromEntries(
            Object.entries(this.data).filter(
              ([key]) =>
                !["nama", "no_rm", "_id", "created_at", "status"].includes(key)
            )
          ),
          created_at: this.data.created_at || Date.now(),
          status: status,
          updated_at: Date.now(),
        },
      })
        .then((r) => {
          this.$root.snackbar.show({
            text: this.id ? "Data berhasil diupdate" : "Data berhasil disimpan",
            right: true,
          });
          if (!this.id) {
            this.$emit("needUpdate");
            this.$router.push({
              name: "form.edit",
              params: { id: r.data._id || this.data._id },
            });
            this.doPrint(r.data._id || this.data._id);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
      this.isLoading = false;
    },
    doPrint(id, custom) {
      // this.$refs.modalPrint.open(this.data_id, this.data.resep)
      this.$refs.modalPrint
        .open(id || this.id, this.data.resep[0] || {}, custom || false)
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    onUpload() {
      // console.log(v);
      // let image = new File([v], `image.png`, { type: "image/png" });
      // console.log(image);
    },
    onScan(model) {
      this.$refs.scanner
        .open()
        .then((val) => {
          this.data[model] = val;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  watch: {
    id(newValue) {
      this.data = this.getClearFormObject();
      this.data.resep.push({ id: Date.now(), obat: [] });

      if (this.$store.state.settings.gizi)
        this.data.resep[0].obat = this.giziObject();

      this.panel = 0;
      this.popup = false;

      if (newValue) {
        this.getData();
        this.panel =
          this.$store.state.settings.resep.enable === "enable" ||
          this.$store.state.settings.gizi
            ? 1
            : 0;
      }
    },
    multiDelete(val) {
      if (val) {
        let select = [];
        this.data.resep[0].obat.forEach(function (item) {
          select.push(item);
        });
        this.select = select;
      } else {
        this.select = [];
      }
    },
  },
};
</script>
<style scoped>
.upload :deep(.v-card) {
  background: lightgrey !important;
}
</style>
