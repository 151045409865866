<template>
  <v-dialog tile persistent v-model="dialog" max-width="400">
    <v-card tile style="background: #fff !important; color: #363636 !important;">
      <v-card-title/>
      <v-card-text>Apakah anda yakin menghapus "<span class="text-uppercase">{{multiple ? data.length + ' item' : name}}</span>"?</v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          depressed
          small
          :loading="isLoading"
          outlined
          class="grey--text col"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          small
          :loading="isLoading"
          color="#00cccc"
          class="white--text col"
          @click="accept"
        >
          Hapus
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import map from 'lodash/map'
export default {
  name: 'ModalDelete',
  props: {
      multiple: { type: Boolean, default: true},
  },
  data () {
    return {
      data: this.multiple ? [] : {},
      url: '/resep/obat/destroy',
      name: null,
      isLoading: false,
      dialog: false,
      resolve : null,
      reject : null,
    }
  },
  methods: {
    open(item, url, name){
      // console.log(item);
      this.data = item
      if(url) this.url = url
      if(name) this.name = name
      return new Promise((resolve, reject)=>{
        this.resolve = resolve
        this.reject = reject
        this.dialog = true
      })
    },
    accept() {
      this.resolve(this.data);
      this.dialog = false;
    },
    cancel() {
      this.reject(this.data);
      this.dialog = false;
    },
  },
}
</script>
